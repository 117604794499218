import { useEffect, useState } from "react";
import {
  getProjectDownloadUrl,
  getProjectFilesTree,
  getProjectMetadata,
} from "./services";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  AlertTitle,
  Alert,
  CircularProgress,
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";

const VIEW = {
  LOADING: "LOADING",
  NOTFOUND: "NOTFOUND",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
};

const FileSystemFolderWrapper = (props) => {
  const { projectId, files, isOpen, setOpen, downloadFile } = props;
  const [loading, setLoading] = useState(true);
  const [metadata, setMetadata] = useState({});

  useEffect(() => {
    const loadData = async () => {
      const descR = await fetch(getProjectMetadata(projectId));
      if (descR.ok) {
        const newMetadata = await descR.json();
        setMetadata(newMetadata);
      }
      setLoading(false);
    };
    loadData();
  }, [projectId]);

  return (
    <>
      {loading && <LinearProgress />}
      {!loading &&
        files?.map((file) => (
          <FileSystemFolder
            metadata={metadata}
            projectId={projectId}
            key={`f_${projectId}_${file.idx}`}
            file={file}
            level={0}
            isOpen={isOpen}
            setOpen={setOpen}
            downloadFile={downloadFile}
          />
        ))}
    </>
  );
};

const FileSystemFolder = ({
  file,
  level,
  downloadFile,
  isOpen,
  setOpen,
  metadata,
}) => {
  const getFileDescription = (file) => {
    const { fileId } = file || {};
    const { descriptions } = metadata || {};
    return descriptions && descriptions[fileId] ? descriptions[fileId] : "";
  };

  if (file.isDirectory) {
    const open = isOpen(file);

    if (file.children?.length) {
      return (
        <>
          <ListItemButton sx={{ pl: 4 * level }} onClick={() => setOpen(file)}>
            <ListItemIcon>
              <FolderIcon />
            </ListItemIcon>
            <ListItemText
              primary={file.name}
              secondary={getFileDescription(file)}
            />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {file.children?.map((file) => {
                return (
                  <FileSystemFolder
                    key={`f_${file.idx}`}
                    file={file}
                    level={level + 1}
                    isOpen={isOpen}
                    setOpen={setOpen}
                    downloadFile={downloadFile}
                    metadata={metadata}
                  />
                );
              })}
            </List>
          </Collapse>
        </>
      );
    } else {
      return (
        <ListItemButton sx={{ pl: 4 * level }}>
          <ListItemIcon>
            <FolderOffIcon />
          </ListItemIcon>
          <ListItemText primary={file.name} secondary="Mappen er tom" />
        </ListItemButton>
      );
    }
  } else {
    return (
      <ListItemButton sx={{ pl: 4 * level }} onClick={() => downloadFile(file)}>
        <ListItemIcon>
          <InsertDriveFileOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          primary={file.name}
          secondary={getFileDescription(file)}
        />
      </ListItemButton>
    );
  }
};

const FileSystemNavigator = ({ showIndex }) => {
  const projectIdUrl = useParams().projectId;
  const projectId = showIndex ? "Forside" : projectIdUrl;
  const [openFolders, setOpenFolders] = useState(true);
  const [filesSet, setFilesSet] = useState([]);
  const [view, setView] = useState(VIEW.LOADING);

  useEffect(() => {
    const loadData = async () => {
      try {
        const r = await fetch(getProjectFilesTree(projectId));
        if (r.ok) {
          const newFiles = await r.json();

          setFilesSet(newFiles);
          setView(VIEW.SUCCESS);
        } else {
          setView(r.status === 404 ? VIEW.NOTFOUND : VIEW.ERROR);
        }
      } catch (e) {
        setView(VIEW.ERROR);
      }
    };

    if (projectId) {
      loadData();
    } else {
      setView(VIEW.NOTFOUND);
    }
  }, [projectId]);

  const isOpen = (file) =>
    file?.idx ? openFolders[file?.idx] === true : false;

  const setOpen = (file) => {
    if (file?.idx) {
      const newOpenFolders = { ...openFolders };
      newOpenFolders[file?.idx] = newOpenFolders[file?.idx] ? false : true;
      setOpenFolders(newOpenFolders);
    }
  };

  const handleDownloadClick = (projectId, file) => {
    if (file.downloadKey) {
      const downloadUrl = getProjectDownloadUrl(projectId, file.downloadKey);
      window.open(downloadUrl, downloadUrl);
    }
  };

  if (view === VIEW.LOADING) {
    return (
      <Container maxWidth="lg" sx={{ paddingTop: "45px", textAlign: "center" }}>
        <CircularProgress color="success" />
      </Container>
    );
  } else if (view === VIEW.ERROR) {
    return (
      <Container maxWidth="md" sx={{ paddingTop: "45px" }}>
        <Alert severity="error">
          <AlertTitle>Feil ved innlasting</AlertTitle>
          Det oppsto en feil ved innlasting av filer. Prøv igjen.
        </Alert>
      </Container>
    );
  } else if (view === VIEW.NOTFOUND) {
    return (
      <Container maxWidth="md" sx={{ paddingTop: "45px" }}>
        <Alert severity="warning">
          <AlertTitle>Område ikke funnet</AlertTitle>
          Området du prøvde å åpne finnes ikke.
        </Alert>
      </Container>
    );
  } else if (view === VIEW.SUCCESS) {
    const multipleFiles = filesSet?.length > 1;

    return filesSet.map((row) => {
      const { projectId, files } = row;

      return (
        <Container
          maxWidth="lg"
          sx={{ paddingTop: "15px" }}
          key={`f_${projectId}`}
        >
          <Accordion defaultExpanded={!multipleFiles}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">{projectId}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List
                sx={{ width: "100%", bgcolor: "background.paper" }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <FileSystemFolderWrapper
                  projectId={projectId}
                  key={`fw_${projectId}`}
                  files={files}
                  isOpen={isOpen}
                  setOpen={setOpen}
                  downloadFile={(f) => handleDownloadClick(projectId, f)}
                />
              </List>
            </AccordionDetails>
          </Accordion>
        </Container>
      );
    });
  } else {
    return "";
  }
};

export default FileSystemNavigator;
